import React from "react";
import { BasicLayout } from "../layouts/BasicLayout";
import { useOnboardingContext } from "../components/PageSegments/onboarding/OnboardingContext";
import { fbq } from "../services/pixel";
import { web_acq_landing_page_view } from "../services/mixpanel/acquisition-events";
import { setMixpanelProperties } from "../services/mixpanel/mixpanel";
import SimplifiedLandingPage from "../components/PageSegments/SimplifiedLandingPage";

export const WEB_PAID_TRIAL_FLOW = "web_paid_trial";

export default () => {
  const { setOnboardingLandingPage } = useOnboardingContext();

  React.useEffect(() => {
    web_acq_landing_page_view();
    fbq("trackCustom", "landing_page_view");
    setOnboardingLandingPage(WEB_PAID_TRIAL_FLOW);
    setMixpanelProperties({
      $LandingPage: WEB_PAID_TRIAL_FLOW,
    });
    sessionStorage.setItem("landing_page", WEB_PAID_TRIAL_FLOW);
  }, [setOnboardingLandingPage]);

  return (
    <BasicLayout>
      <SimplifiedLandingPage />
    </BasicLayout>
  );
};
